<template>
  <div>
    <v-row class="align-center">
      <v-col class="pa-0 pl-5 pt-5 ma-0" cols="12" md="4">
        <v-icon class="rotate-90">mdi-sort-variant</v-icon>
        <strong class="ml-2">{{ $t("details_page.indicators_title") }}</strong>
      </v-col>
      <v-col class="d-flex align-center py-0 my-0" cols="12" md="8" style="flex-wrap: wrap; justify-content: flex-end">

        <v-btn class="down-btn" height="48" width="48">
          <img :src="imgDown" height="20" width="20" />
        </v-btn>
        <v-btn class="down-btn mx-4" height="48" width="48">
          <img :src="imgPrint" height="20" width="20" />
        </v-btn>
        <v-col md="3" sm="6" class="elevation-0 d-flex align-center">
          <v-select
            :items="itemsSelects"
            v-model="filterType"
            :label="$t('my_campaigns_page.filter_by')"
            @input="changeFilter"
            background-color="#F5F9FC"
            class="select pt-6"
            item-value="value"
            item-text="label"
            height="48"
            dense
            solo
            flat
          />
        </v-col>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 align-center" v-if="isUpdatingStatisticsInfo">
      <v-col class="px-1" cols="12" md="2" sm="3" v-for="i of 6" :key="i">
        <v-skeleton-loader class="chip-field border-16" type="card" />
      </v-col>
    </v-row>
    <div class="pa-0 ma-0 d-flex align-center width-100" style="width: 100%; flex-wrap: wrap; gap: 10px;" v-else>
      <div class="px-1">
        <v-chip class="chip-field pl-4" color="#F5F9FC" style="min-width: 185px">
          <strong>{{ $t("details_page.impressions_text") }}</strong>
          <h2 class="mt-10">{{ statistics.impressions }}</h2>
        </v-chip>
      </div>
      <div class="px-1" style="width: 190px">
        <v-chip
          class="chip-field pl-4 pr-10"
          color="#F5F9FC"
          style="min-width: 185px"
        >
          <strong>{{ $t("details_page.clicks_text") }}</strong>
          <h2 class="mt-10">{{ statistics.clicks }}</h2>
        </v-chip>
      </div>
      <div class="px-1">
        <v-chip class="chip-field pl-4" color="#F5F9FC" style="min-width: 200px">
          <strong>{{ $t("details_page.ctr_text") }}</strong>
          <h2 class="mt-10">{{ getRoundedNumbers(statistics.ctr ) }}%</h2>
        </v-chip>
      </div>
      <div class="px-1">
        <v-chip class="chip-field pl-4" color="#F5F9FC" style="min-width: 185px">
          <strong>{{ $t("details_page.cpc_text") }}</strong>
          <h2 class="mt-10">${{ getRoundedNumbers(statistics.cpc)  }}</h2>
        </v-chip>
      </div>
      <div class="px-1">
        <v-chip class="chip-field pl-4" color="#F5F9FC" style="min-width: 185px">
          <strong>{{ $t("details_page.cost_text") }}</strong>
          <h2 class="mt-10">${{ getRoundedNumbers(statistics.cost) }}</h2>
        </v-chip>
      </div>
      <div class="px-1">
        <v-chip class="chip-field pl-4" color="#F5F9FC" style="min-width: 200px">
          <strong>{{ $t("status_text") }}</strong>
          <div
            class="align-center d-flex justify-space-between mt-10"
            style="width: 100%;flex-wrap: wrap"
          >
            <div>
              <div class="d-flex align-center">
                <div
                  class="align-center py-1 px-3"
                  :class="
                    GETCAMPAIGN_STATUS[campaign.status] === 'active_chip'
                      ? 'Active'
                      : 'Paused'
                  "
                  style="border-radius: 10px"
                >
                  <img
                    :src="activeIcon"
                    v-if="GETCAMPAIGN_STATUS[campaign.status] === 'active_chip'"
                    class="mr-1"
                    width="14"
                    height="14"
                  />
                  <v-icon
                    v-if="GETCAMPAIGN_STATUS[campaign.status] === 'paused_chip'"
                    color="#F2994A"
                    small
                  >
                    mdi-pause
                  </v-icon>
                  <span
                    class="ml-1 font-family-raleway-bold text-uppercase"
                    style="font-size: 12px"
                  >
                    {{ $t(GETCAMPAIGN_STATUS[campaign.status]) }}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <v-btn
                v-if="GETCAMPAIGN_STATUS[campaign.status] === 'active_chip'"
                :loading="isUpdatingStatus"
                class="activate-btn font-weight-bold"
                color="error"
                small
                text
                @click="handleClickActivateDe"
              >
                {{ $t("pause") }}
              </v-btn>
              <v-btn
                v-else
                :loading="isUpdatingStatus"
                class="activate-btn font-weight-bold text-right"
                @click="handleClickActivateDe"
                :disabled="campaign.status == 5"
                color="primary"
                text
                small
              >
                {{ $t("details_page.activate_button") }}
              </v-btn>
            </div>
          </div>
        </v-chip>
      </div>
    </div>
  </div>
</template>

<script>
import { CAMPAIGN_STATUSES } from "@/utils";
import { campaignService } from "@/services/campaign";
import imgDown from "@/assets/img/down.svg";
import imgPrint from "@/assets/img/print.svg";
import activeIcon from "@/assets/icon/active-status-icon.svg";
import moment from "moment";
import "moment-timezone";
import { accountService } from "@/services/account";
import axios from "axios";
import { baseUrl } from "@/main";

export default {
  name: "IndicatorsSection",
  props: {
    campaign: Object,
    customerId: Number,
  },
  data() {
    return {
      filterType: "",
      isUpdatingStatus: false,
      isUpdatingStatisticsInfo: false,
      statistics: {},
      imgDown,
      imgPrint,
      activeIcon,
    };
  },
  computed: {
    GETCAMPAIGN_STATUS() {
      return CAMPAIGN_STATUSES;
    },
    itemsSelects() {
      return [
        {
          value: 0,
          label: this.$t("details_page.filter_today"),
        },
        {
          value: 1,
          label: this.$t("details_page.filter_yesterday"),
        },
        {
          value: 2,
          label: this.$t("details_page.filter_last7days"),
        },
        {
          value: 3,
          label: this.$t("details_page.filter_last_business_week"),
        },
        {
          value: 4,
          label: this.$t("details_page.filter_this_month"),
        },
        {
          value: 5,
          label: this.$t("details_page.filter_last_month"),
        },
        {
          value: 6,
          label: this.$t("details_page.filter_last_14month"),
        },
        {
          value: 7,
          label: this.$t("details_page.filter_last_30month"),
        },
        {
          value: 8,
          label: this.$t("details_page.filter_last_this_week_Sun_Today"),
        },
        {
          value: 9,
          label: this.$t("details_page.filter_last_this_week_Mon_Today"),
        },
        {
          value: 10,
          label: this.$t("details_page.filter_last_this_week_Sun_Sat"),
        },
        {
          value: 11,
          label: this.$t("details_page.filter_last_this_week_Mon_Sun"),
        },
        {
          value: 12,
          label: this.$t("details_page.filter_all_time"),
        },
      ];
    },
  },
  async created() {
    const { statistics } = this.campaign;
    this.statistics = statistics;
    this.timeZone = await this.getTimeZoneFromAccount();
  },
  methods: {
    getRoundedNumbers(num) {
      return Math.round(num * 100) / 100;
    },
    async changeFilter(val) {
      this.filterType = val;
      const { id } = this.$route.params;
      const customerId = localStorage.getItem("customerId");
      const payload = {
        customerId: parseInt(customerId),
        predefined: true,
        period: val,
      };
      this.isUpdatingStatisticsInfo = true;
      try {
        const { data } = await axios.post(
          `${baseUrl}/campaigns/getCampaign/${id}`,
          payload
        );
        this.statistics = data;
      } catch (err) {
        console.log(err);
      }
      this.isUpdatingStatisticsInfo = false;
    },
    async getTimeZoneFromAccount() {
      const accountId = this.customerId;
      const { data } = await accountService.getByCustomerId(accountId);
      const { timeZone } = data;
      return timeZone;
    },
    async handleClickActivateDe() {
      const { id } = this.$route.params;
      this.isUpdatingStatus = true;
      try {
        const response = await campaignService.toggleStatus(id);
        this.$emit("setCampaign", response);
      } catch (err) {
        const errCode = err.response?.statusText;
        this.$notify({
          group: "foo",
          type: "error",
          title: errCode || this.$t("something_wrong"),
        });
      }
      this.isUpdatingStatus = false;
    },
    getYesterday() {
      const today = moment(); // get the current date and time
      const yesterday = today.clone().subtract(1, "day");
      return yesterday.format("Do");
    },
    getStatisticsDates(items) {
      const yesterday = this.getYesterday();
      return items.filter((item) => item.includes(yesterday));
    },
    getStatistics(items) {
      const yesterday = this.getYesterday();
      return items.filter((item) =>
        moment(item?.dateTimestamp)
          .tz(this.timeZone)
          .format("Do, h:mm a")
          .includes(yesterday)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.chip {
  height: 28px;
  border-radius: 10px;
  padding: 0 10px !important;
  text-transform: uppercase;
}

.chip-field {
  height: 120px;
  width: 100%!important;

  strong {
    position: absolute;
    font-size: 16px;
    color: #828282;
    top: 16px;
    left: 16px;
  }

  h2 {
    color: #0057ff;
    font-size: 36px;
  }
  .activate-btn {
    box-shadow: none !important;
    text-transform: capitalize !important;
    color: #0057ff;
  }
}
@media screen and (max-width: 1320px) {
  .width-100 > .px-1 ,{
    width: calc((100% - 30px)/4)!important;
  }
}

@media screen and (max-width: 1240px) {
  .width-100 > .px-1 ,{
    width: calc((100% - 30px)/4)!important;
  }
}
@media screen and (max-width: 920px) {
  .width-100 > .px-1 ,{
    width: calc((100% - 20px)/3)!important;
  }
}
@media screen and (max-width: 720px) {
  .width-100 > .px-1 {
    width: calc((100% - 10px)/2)!important;
  }
}
@media screen and (max-width: 450px) {
  .width-100 > .px-1 {
    width: 100%!important;
  }
}

</style>
